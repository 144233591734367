import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ReactNode, useEffect, useState } from "react";

interface ModalProps {
  open: boolean;
  withClose?: boolean;
  className?: string;
  onClose?: () => void;
  children: ReactNode;
}

export const Modal = ({
  open,
  withClose,
  className,
  onClose,
  children,
}: ModalProps) => {
  const [showModal, setShowModal] = useState(open);

  useEffect(() => {
    if (open && !showModal) {
      const timer = setTimeout(() => setShowModal(true), 150);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => setShowModal(false), 150);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!(showModal || open)) return;

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={(event) => {
        event.stopPropagation();
        handleClose();
      }}
    >
      <div
        className={classNames(
          "fixed inset-0 bg-black/40 backdrop-blur-sm max-w-full transition-all duration-150",
          {
            "opacity-100": open,
            "opacity-0": !open,
          }
        )}
        aria-hidden="true"
      />

      <div className="fixed inset-0 z-10 w-screen max-h-screen py-12">
        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <div
            className={classNames(
              "relative transform overflow-y-auto max-h-[80vh] rounded-lg bg-white text-left shadow-xl transition-all duration-150 sm:my-8 w-[90%] sm:max-w-xl md:max-w-screen-lg",
              {
                "opacity-100 scale-100": open,
                "opacity-0 scale-90": !open,
              }
            )}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              className={classNames(
                "bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4",
                className
              )}
            >
              {withClose && (
                <div className="w-full flex justify-end">
                  <XMarkIcon
                    onClick={handleClose}
                    className="w-8 h-8 text-sendolab-gray cursor-pointer z-10"
                  />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
