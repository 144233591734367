import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useState } from "react";

interface QuestionItemProps {
  color: "black" | "white";
  question: string;
  answer: string;
  line?: boolean;
}

export const QuestionItem = ({
  color,
  question,
  answer,
  line = true,
}: QuestionItemProps) => {
  const [active, setActive] = useState<boolean>(true);

  return (
    <div
      className={`w-full flex flex-col justify-center text-${color} overflow-hidden`}
    >
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <p
          className="text-lg md:text-2xl lg:text-3xl font-light my-2 pr-6"
          dangerouslySetInnerHTML={{ __html: question }}
        />
        <ChevronDownIcon
          className={`text-${color} w-10 h-10 ${active ? "" : "rotate-180"} transition-all`}
        />
      </div>
      <p
        className={classNames(
          "text-sm md:text-base font-light transition-all",
          {
            "h-0": !active,
            "h-28": active,
          }
        )}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
      {line && <div className={`w-full h-[2px] bg-${color}`}></div>}
    </div>
  );
};
